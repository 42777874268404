import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { FaShoppingCart } from "react-icons/fa";
import "./CartButton.css";
import ParkingPlanModal from "./ParkingPlanModal";
import Constants from "./Constants";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTimer } from './TimerContext';
import { Container } from "react-bootstrap";


const CartButton = ({ productId, openModal }) => {
  const { startTimer } = useTimer();

  const Project_Id = Constants.PROJECTID;
  const storedAuthorId = sessionStorage.getItem("authorid");
  const [cartId, setCartId] = useState(sessionStorage.getItem("cart_id"));
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productName, setProductName] = useState("");
  const [addedToCart, setAddedToCart] = useState(false); // Track if the item is added to the cart
  // const [showTimer, setShowTimer] = useState(false);
  // const [remainingTime, setRemainingTime] = useState(180); // 3 minutes in seconds
  const [isParkingModalOpen, setParkingModalOpen] = useState(false);
  // const closeParkingModal = () => {
  //   setParkingModalOpen(false);
  // };
  
  // const closeMModal = () => {
  //   setIsModalOpen(false);
  // };
  useEffect(() => {
    const fetchProductName = async () => {
      try {
        const response = await axios.get(
          `https://vanityapi.adef.tech/Home/Productview?project_id=${Project_Id}&id=${productId}`
        );
        setProductName(response.data.name);
      } catch (error) {
        console.error("Error fetching product name:", error);
      }
    };

    fetchProductName();
  }, [productId, Project_Id]);
  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };
  const handleAddToCart = useCallback(() => {
    const cartid = sessionStorage.getItem("cartId");
    cartDelete(cartid);
    
      const qty = "1";
      const apiUrl = "https://vanityapi.adef.tech/Cart/AddCart";
      const requestData = {
        project_id: Project_Id,
        product_id: productId,
        quantity: qty,
      };
      const data = new URLSearchParams(requestData);

      // if (cartId) {
      //   requestData.cart_id = cartId;
      //   sessionStorage.setItem("cartId", requestData.cart_id);
      // }

      fetch(apiUrl, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": 'application/x-www-form-urlencoded',
        },
        // body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.responseCode === 1) {

            
            sessionStorage.setItem("cartId", data.cart_id);
            setCartId(data.cart_id);
            showSuccessMessage("Product successfully added to cart");
            setModalVisible(false);
            setAddedToCart(true);
            sessionStorage.removeItem("parkingplan");
            sessionStorage.setItem('cartcount', 1);
            startTimer(29, 0);
            // setParkingModalOpen(true);
            // setShowTimer(true);
          } else {
            showError(data.responsemessage);
          }
        })
        .catch((error) => {
          console.error("Fetch Error:", error);
          showError("An error occurred while adding the product to the cart.");
        });
  }, [ productId, Project_Id, setCartId,startTimer, setModalVisible, setAddedToCart]);
  const cartDelete = (id) => {
    if (id) {
      const formData = { id: id };
      const data = new URLSearchParams(formData);

      fetch("https://vanityapi.adef.tech/Cart/Delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.responseCode === 1) {
            const showSuccessToast = () => {

              toast.success("Cart item deleted.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            };
            showSuccessToast();
            sessionStorage.removeItem("cartId");
            sessionStorage.removeItem("parkingplan");
            // window.location = "/";
          } else {
            const showErrorToast = () => {
              toast.error(data.responsemessage, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            };
            showErrorToast();
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    }
  };

  // useEffect(() => {
  //   let countdownInterval;

  //   if (showTimer && remainingTime > 0) {
  //     countdownInterval = setInterval(() => {
  //       setRemainingTime((prevTime) => prevTime - 1);
  //     }, 1000);
  //   }

  //   return () => {
  //     clearInterval(countdownInterval);
  //   };
  // }, [showTimer, remainingTime]);

  // useEffect(() => {
  //   if (showTimer && remainingTime === 0) {
  //     // Reset the timer and hide it
  //     setRemainingTime(180); // Reset to 3 minutes
  //     setShowTimer(false);
  //     const cartDelete = (id) => {
  //       if (id) {
  //         const formData = { id: id};
  //         const data = new URLSearchParams(formData);
    
  //         fetch("https://vanityapi.adef.tech/Cart/Delete", {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/x-www-form-urlencoded",
  //           },
  //           body: data
  //         })
  //           .then((response) => {
  //             if (!response.ok) {
  //               throw new Error(`HTTP error! Status: ${response.status}`);
  //             }
  //             return response.json();
  //           })
  //           .then((data) => {
  //             if (data.responseCode === 1) {
  //               const showSuccessToast = () => {
  //                 toast.success("Cart item deleted.", {
  //                   position: 'top-right',
  //                   autoClose: 3000,
  //                   hideProgressBar: false,
  //                   closeOnClick: true,
  //                   pauseOnHover: true,
  //                   draggable: true,
  //                 });
  //               };
  //               showSuccessToast();
  //               window.location = "/";
  //             }
  //             else{
  //                 const showErrorToast = () => {
  //                   toast.error(data.responsemessage, {
  //                     position: 'top-right',
  //                     autoClose: 3000,
  //                     hideProgressBar: false,
  //                     closeOnClick: true,
  //                     pauseOnHover: true,
  //                     draggable: true,
  //                   });
  //                 };
  //                 showErrorToast();
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("API Error:", error);
  //           });
  //       }
  //     };
  //     const cartid = sessionStorage.getItem("cartId");
  //     cartDelete(cartid);
  //   }
  // }, [showTimer, remainingTime]);

  // const minutes = Math.floor(remainingTime / 60);
  // const seconds = remainingTime % 60;
  const handleCheckout = () => {
    setIsModalOpen(true); // Open the modal on checkout
  };

  const showSuccessMessage = (message) => {
    const showSuccessToast = () => {
      toast.success(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Callback function to be executed when the toast is closed
          window.location.reload(); // Refresh the page
        },
      });
    };
     // Call the showSuccessToast function
    showSuccessToast();
  };

  const showError = (message) => {
    const showErrorToast = () => {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    };
    showErrorToast();
  };

  return (
    <>
      {!addedToCart ? (
        <button onClick={() => handleAddToCart()} className="btn buybutton">
          <FaShoppingCart /> &nbsp;Buy Now
        </button>
      ) : (
        <button onClick={() => handleCheckout()} className="btn buybutton">
          Checkout
        </button>
      )}

      {/* Render the ParkingPlanModal conditionally based on isModalOpen */}
      {isParkingModalOpen && (
        <ParkingPlanModal 
          isOpen={isParkingModalOpen}
          onClose={() => setParkingModalOpen(false)}
          productId={productId}
          phonenumber={productName}
          createdby={storedAuthorId}
        />
      )}
      <ToastContainer/>
      </>
  );
};

export default CartButton;
