import React from 'react';
import { Container } from 'react-bootstrap';
import "./Digitalcoldstorage.css"; // You can create a CSS file for styling

const Digitalcoldstorage = () => {
    return (
        <Container fluid className='digital-cold-storage-section-container p-2'>
        <Container>
            <section className="digital-cold-storage-section mt-4">
                <h1>Digital Cold Storage or Parking</h1>
                <p>
                    If you're leaving the country for an extended trip, paying for your U.S. cellphone, Landline, or Toll-Free service doesn't make sense.
                    Still, if you've grown attached to the number and want to use it again, services will park/store that number so that it's waiting for you when you return.
                </p>
                <p>
                    Parking your phone number allows you to maintain ownership of that number so that you don't have to get and give out a new phone number in the future.
                    Don’t worry, your Number is always with you even after hard times.
                </p>
                <ul>
                    <li>You sold your business and planning to start a new Business Soon.</li>
                    <li>You lost someone in your family, but their number is still with you.</li>
                    <li>Leaving the country for higher education.</li>
                    <li>Lost your job, but you want to use the same number in a new job.</li>
                    <li>Hospitalized, then you can Pause Incoming calls temporarily.</li>
                    <li>You don’t use your number temporarily because you are a short-term freelancer.</li>
                    <li>You got an extension to your number then you can store sometime for future Kids, Future Wife, or Girlfriend.</li>
                </ul>
                <p>
                    We store your number in our digital Cold Storage. Your Number is always with you even after hard times.
                </p>
                <p>
                    The Storage Fee will be low if you choose a long term like 6 months or 1 year.
                    Our plans $4.99/Month, $24/6 months, and $39.99/1 year.
                </p>
                <h2>Simple Benefits</h2>
                <ul>
                    <li>Activate it anytime.</li>
                    <li>Port out anytime.</li>
                    <li>No contracts.</li>
                    <li>No setup fees.</li>
                    <li>No porting fees.</li>
                    <li>No cancellation fees.</li>
                </ul>
            </section>
        </Container>
        </Container>
    );
};

export default Digitalcoldstorage;
