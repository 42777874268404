import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import CartButton from "./CartButton";
import SideNav from "./SideNav";
import "./IndustryNumbers.css";
import Sorting from "./Sorting";

function IndustryNumbers() {
  const { industryId } = useParams();
  const [industryNumbers, setIndustryNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState("lowToHigh"); // Default sorting order

  useEffect(() => {
    axios
      .get(
        `https://vanityapi.adef.tech/Home/phonenumberslisting?industrytype=${industryId}`
      )
      .then((response) => {
        setIndustryNumbers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      });
  }, [industryId]);

  // Sort phone numbers based on the selected order
  const sortedPhoneNumbers = [...industryNumbers].sort((a, b) => {
    const priceA = a.rate;
    const priceB = b.rate;

    return sortBy === "lowToHigh" ? priceA - priceB : priceB - priceA;
  });
  // Check if there are no phone numbers
  if (loading) {
    return <p>Loading...</p>;
  }
  if (sortedPhoneNumbers.length === 0) {
    var nonumber = <p>No phone numbers available for {industryId}.</p>;
  }

  if (error) {
    return (
      <Container fluid>
        <Row className="mt-5r">
          <h2 className=" text-center">Numbers for Industry: {industryId}</h2>
        </Row>
        <Row className="mt-3">
          <div className="error-message">Error: {error.message}</div>
        </Row>
      </Container>
    );
  }
  if (sortedPhoneNumbers.length === 0) {
    nonumber = <p>No phone numbers available for {industryId}.</p>;
  }

  const numberList = sortedPhoneNumbers.map((item) => (
    <Col md={12} key={item.Id}>
      <div className="box">
        <Row>
          <Col md={6}>
            <p className="box-number text-start ">{item.name}</p>
          </Col>
          <Col md={6}>
            <p className="box-price text-end m-0">
              ${parseFloat(item.rate).toFixed(2)}(
              <span className="box-discount text-end">
                {item.discount}% Off {item.price}
              </span>
              )
            </p>
          </Col>
          <Col md={6}>
            <button className="location-button mb-3">
              <FaMapMarkerAlt /> {item.state}
            </button>
            <p className="box-patterns m-0">Pattern: {item.pattern}</p>
            <p className="activatetext">Activate in 2-7 days</p>
          </Col>
          <Col md={6} className="mt-auto">

            {item.stock > 0  ?  (
              <>
                <p className="statustext">{item.status}</p>
<CartButton
                  productId={item.id}
                  className="buybutton"
                ></CartButton>
                {item.rate > 5000 && (
                  <>
                    <p className="offertext">You can Make an Offer</p>
                  </>
                )}
              </>
            ) : (
              <p className="sold-out-text text-end">Sold Out</p>
            )}
          </Col>
          <Col md={6}></Col>
        </Row>
      </div>
    </Col>
  ));

  return (
    <Container fluid>
      <Row className="mt-5 text-center">
        <h2>Numbers for Industry : {industryId}</h2>
      </Row>
      <Container className="pb-5">
        <Row className="mt-3">
          <Col md={4}>
            <SideNav />
          </Col>
          <Col className={8}>
            <Sorting setSortBy={setSortBy} />
            <Row>
              {numberList}
              {nonumber}
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default IndustryNumbers;
