import React, { useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import './Aboutus.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Aboutus = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  const renderCard = (title, content, animation) => (
    <Card data-aos={animation}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{content}</Card.Text>
      </Card.Body>
    </Card>
  );

  return (
    <Container fluid className='nd p-2'>
    <Container>
    <div className="about-us-content mt-5 mb-5">
      <h2 data-aos="fade-up">About Us</h2>

      {renderCard(
        'Our Story',
        `Ours is Atlanta Based Company, we are in this business since 2006, we are Experts in phone numbers. We know the Process and we have knowledge to make it easier for our customers. We work very hard for you because you are a valuable customer to us. We will give more priority to Customer satisfaction and next we think about Money matters.\n\n`
        + 
    `  Our Business Model is Friendly and Genuine. Money is important for life, we know how the person spends money and time. The value of money depends upon the time we have invested. On the other hand, Time is more valuable than money. Researching for a product, it consumes a lot of time for finding a right and good product. You don’t need to spend a lot of time to make purchase on our website, because we spend a lot of time for you to get it done.`,
        'fade-right'
      )}

      {renderCard(
        'Our Mission',
        `We’re on National wide in search of good and Trusted, US Owned company for VIP Phone Numbers to share with our North American community. We’re pushing limits in new Phone Numbers with our Knowledge. We want it to be as easy as possible to make happy every customer, explore and become more trusted and popular through Numbers.`,
        'fade-left'
      )}

      {renderCard(
        'Our Vision',
        `To become the most Trusted in North America, getting ready to own more Phone Numbers, and towards most famous for the Best.`,
        'fade-right'
      )}

      {renderCard(
        'Contact Us',
        'Number Karma, LLC. 525 Tribble Gap Rd #3351. Cumming, Georgia 30040.',
        'fade-left'
      )}
    </div>
    </Container>
    </Container>
  );
};

export default Aboutus;
