import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Cart from './Cart';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Constants from './Constants';
import Login from './Login';
import Sign from './Sign';

const ParkingPlanModal = ({ isOpen,onClose, showTimer, minutes, seconds , onRequestClose, parkingPlans, productId, phonenumber, createdby, openLoginModal }) => {
  const [fetchedParkingPlans, setFetchedParkingPlans] = useState([]);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  const [IsOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const authorid = sessionStorage.getItem("authorid");
  const Project_Id = Constants.PROJECTID;
  const [productid, setProductid] = useState(0);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  const handleShowSignUpModal = () => setShowSignUpModal(true);
  const handleCloseSignUpModal = () => setShowSignUpModal(false);
// to get parking plans
  const getParkingPlans = async () => {
    try {
      const response = await axios.get('https://vanityapi.adef.tech/Park/Parkingplans');
      return response.data; // Assuming the response contains an array of parking plans
    } catch (error) {
      console.error('Error fetching parking plans:', error);
      throw error; // Handle the error as needed
    }
  };
// create parking
// const createParking = async (planId, planname) => {
//   try {
//     const planDetailsResponse = await axios.get(`https://vanityapi.adef.tech/Park/Parkingplan/${planId}`);
//     setSelectedPlanDetails(planDetailsResponse.data);
//   } catch (error) {
//     console.error('Error fetching parking plan details:', error);
//   }
// };
onClose = () => {
  window.location.reload();
}
// set the selected plan
  useEffect(() => {
    const fetchParkingPlans = async () => {
      try {
        const plans = await getParkingPlans();
        setFetchedParkingPlans(plans);
      } catch (error) {
        // Handle error
      }
    };

    fetchParkingPlans();
  }, []);
  const cartid = sessionStorage.getItem("cartId");
  const [cartAmount, setCartAmount] = useState(0);
  const fetchCartIndex = async () => {
    const cartid = sessionStorage.getItem("cartId");

    try {
      if (cartid) {
        const response = await fetch(
          `https://vanityapi.adef.tech/Cart/Index?project_id=${Project_Id}&cart_id=${cartid}`
        );
        const data = await response.json();
        if (data.length > 0 && data !== "Cart is empty") {
          setProductid(data[0].product_id);
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  fetchCartIndex();
  const fetchCartAmount = async () => {
    try {
      if (cartid) {
        const response = await fetch(
          `https://vanityapi.adef.tech/Cart/Cartamount?project_id=${Project_Id}&cart_id=${cartid}`
        );
        const data = await response.json();
        if (data) {
          const amount = parseFloat(data.price).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          setCartAmount(amount);
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  fetchCartAmount();

  return (
    <>
    <Modal
    show={isOpen} onHide={onClose}
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Shopping Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body className=''>
        {/* Render the parking plans here */}
        
        <Cart parkingPlans={fetchedParkingPlans} selectedPlanDetails={selectedPlanDetails} isOpen={IsOfferModalOpen} onRequestClose={() => setIsOfferModalOpen(false)} showTimer={showTimer} minutes={minutes} seconds={seconds}/>
        <Container>
        <Row>
        {cartid ? (
          authorid ? (
            <></>
          ) : (
            <Col md={4}>
              <Button className="btn my-5" onClick={handleShowLoginModal}>
                Login
              </Button>
              <Login show={showLoginModal} handleClose={handleCloseLoginModal} openSignUpModal={handleShowSignUpModal} />
              <Sign show={showSignUpModal} handleClose={handleCloseSignUpModal} openLoginModal={handleShowLoginModal} />
            </Col>
          )
        ) : (
          <></>
        )}

        
        </Row>
        </Container>
      </Modal.Body>
    </Modal>
    {/* Render the LoginModal conditionally based on showLoginModal */}
    {showLoginModal && (
      <Login
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        // Add other necessary props for the LoginModal
      />
    )}
    </>
  );
};

export default ParkingPlanModal;
