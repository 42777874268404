import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Local.css';

function Local() {
    return ( 
        <>
        <Container className="mt-5">
        <Link to="/numberBank" className="searchengine">Go to Search Engine</Link>
            <h2 className="faq-container mt-4">New Local and Toll-Free Numbers</h2>
            <div className="faq-container mt-4">
            <div className="faq-item">
                <h3>How many new phone numbers does Numberkarma have available?</h3>
                <p>
                We have 43 Millions phone numbers available to search and purchase. That's the world's largest database of new local and toll free numbers available!
                </p>
            </div>
            <div className="faq-item">
                <h3>What phone numbers and area codes are available for purchase?</h3>
                <p>
                Numberkarma offers many local area codes throughout the US. Looking is free and easy.
                </p>
            </div>
            <div className="faq-item">
                <h3>Will I own the number I purchase from Numberkarma?</h3>
                <p>
                Yes! Once the number is active in your account, it is yours to keep. Use it with Numberkarma or port away to another carrier.                </p>
            </div>
            <div className="faq-item">
                <h2>Format of a Local Telephone Number: </h2>
                <p>(NPA) NXX-XXXX</p>
                <img src="/images/numberpattern.png"></img>
                <p>In the US and its territories, Canada, and the Caribbean, the organization and allocation of telephone numbers is governed by the North American Numbering Plan Administration (NANPA). NANPA organizes the allocation of area codes and telephone prefixes to various phone companies. The basic format of a phone number in any of these countries is NPA-NXX-XXXX or (NPA) NXX-XXXX.</p>
<p>NPA: Area codes came into use during the early 1940s. NPA codes were developed by AT&T and the Bell System to divide the coverage area into "number plan areas" (abbreviated NPA). NPA codes are more commonly referred to as area codes. While the system was developed in the 40s, direct dialing of long distance did not begin until the early 50s. Some area codes are reserved for special purposes. For instance, area code 800 (commonly referred to as 800-numbers) is reserved for toll free calls where the called party is charged instead of the calling party. Also, not all area codes are currently in use.</p>
<p>NXX: The next three digits of a landline number or cellphone number are called the NXX. The NXX is also known as the prefix or exchange. Various telephone carriers will reserve blocks of telephone numbers by reserving an NXX within an area code. Like area codes, not all prefixes are currently in use.</p>
<p>Subscriber: Finally, the final 4 digits of the phone number are known as the subscriber or local number. Based on the total number of active NPA and NXX combinations reserved and that each one could have up to 10,000 possible subscriber numbers, the current total possible number of telephone numbers is 1,699,140,000. Based on the total population of the US and Canada according to the US Census and the World Bank, that leaves 4 phone numbers for every person. Remember though that phone numbers are no longer just used for standard home phones. Many telephone numbers are now used for fax machines, cell phones or wireless phones, or internet connections so one person may actually need multiple phone lines.</p>

            </div>
         
        </div>
        </Container>
        </>
     );
}

export default Local;