import { Col, Container, Image, Row } from "react-bootstrap";

function VirtualPhoneNumbers() {
  return (
    <>
      <Container className="py-5">
        <div className="faq-container mt-4">
          <h2>Virtual Phone Numbers</h2>
          <div className="faq-item">
            <Row className="align-items-center">
              <Col md={8}>
                <p>
                  Virtual phone numbers tend to still be a little esoteric, even
                  though people who are running a business usually have some
                  idea of what they are. In reality, there isn’t too much
                  difference between a virtual phone number and a regular phone
                  number, and in fact, it’s helpful to think of a virtual number
                  simply as a regular number that happens to be hosted by a
                  virtual service provider. Here’s what you can do with your
                  virtual phone number.
                </p>
              </Col>
              <Col md={4}>
                <Image src="/images/vpn1.png" className="img-fluid"></Image>
              </Col>
            </Row>
          </div>
          <div className="faq-item">
            <Row className="align-items-center">
              <Col md={8}>
                <h3>Make Calls:</h3>
                <p>
                Even though a virtual number is not attached to a phone, you can make outbound calls from your existing phones, while showing your virtual number on the caller ID (as part of a virtual phone system.
                </p>
              </Col>
              <Col md={4}>
                <Image src="/images/vpn2.png" className="img-fluid"></Image>
              </Col>
            </Row>
          </div>
          <div className="faq-item">
            <Row className="align-items-center">
              <Col md={8}>
                <h3>Receive Calls:</h3>
                <p>
                People can dial your virtual phone number to reach you just like any other phone number, and the experience is exactly the same for the caller. The only difference is that, when the call comes in through your virtual number, it is then immediately routed to your phones and begins to ring those phones.
                </p>
              </Col>
              <Col md={4}>
                <Image src="/images/vpn3.png" className="img-fluid"></Image>
              </Col>
            </Row>
          </div>
          <div className="faq-item">
            <Row className="align-items-center">
              <Col md={8}>
                <h3>Send & Receive Text Messages:</h3>
                <p>
                Text messaging can also be enabled on a virtual number. To your customers, it appears and functions exactly like normal text messaging, and to you, it’s actually augmented. Depending on what texting service you are using, not only can you send and receive texts normally, but you can also manage and organize your messages.
                </p>
              </Col>
              <Col md={4}>
                <Image src="/images/vpn4.png" className="img-fluid"></Image>
              </Col>
            </Row>
          </div>
          <div className="faq-item">
            <Row className="align-items-center">
              <Col md={8}>
                <h3>Transfer it to Any Carrier:</h3>
                <p>
                The FCC protects your right to keep your current phone number, and this applies to virtual numbers, too. This means that if you leave your virtual service and want to keep your number, then you can port it out to a cell phone, landline, or even VoIP carrier. It is a real phone number; we only call it a “virtual” number if it is currently hosted by virtual phone service.
                </p>
              </Col>
              <Col md={4}>
                <Image src="/images/vpn5.png" className="img-fluid"></Image>
              </Col>
            </Row>
          </div>
          <div className="faq-item">
            <Row className="align-items-center">
              <Col md={8}>
                <h3>Add Extensions:</h3>
                <p>
                As part of a virtual phone system, you can even add traditional extensions in the same way that you would with a conventional wired office system, and they can be assigned to individuals or departments.
                </p>
              </Col>
              <Col md={4}>
                <Image src="/images/vpn6.png" className="img-fluid"></Image>
              </Col>
            </Row>
          </div>
          <div className="faq-item">
            <Row className="align-items-center">
              <Col md={12}>
                <h3>Add a Menu:</h3>
                <p>
                Making it sound professional is a top priority when setting up a phone system, which is why you may want the use of a Call Menu, or auto-attendant so that your callers can press a number and be connected to the right person. Fortunately, this feature can easily be added to a virtual phone number.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}

export default VirtualPhoneNumbers;
