import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Cart.css";
import Constants from "./Constants";
import MakeAnOfferButton from "./MakeAnOfferButton";
import ParkingPlanModal from "./ParkingPlanModal";
import PayPalComponent from "./PayPalComponent";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTimer } from "./TimerContext";
import Login from "./Login";
import Sign from "./Sign";

function Cart({ selectedPlanDetails, isOpen, onRequestClose }) {
  const { seconds, minutes, showTimer, timerComplete } = useTimer();

  const [cartData, setCartData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [cartAmount, setCartAmount] = useState(0);
  const [paypalAmount, setPaypalAmount] = useState(0);
  const [productid, setProductid] = useState(0);
  const [selectedPlanIds, setSelectedPlanIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const Project_Id = Constants.PROJECTID;
  const authorid = sessionStorage.getItem("authorid");
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState({}); // Track selected plans for each item
  const [isSuccess, setIsSuccess] = useState(false);
  const [isParkingModalOpen, setParkingModalOpen] = useState(false);
  const [appliedPlan, setAppliedPlans] = useState({});
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  const handleShowSignUpModal = () => setShowSignUpModal(true);
  const handleCloseSignUpModal = () => setShowSignUpModal(false);
  const closeParkingModal = () => {
    setParkingModalOpen(false);
  };
  const parkingPlans = [
    { id: 0.00, planname: "No Parking Plan" },
    { id: 3.00, planname: "1 month" },
    { id: 8.00, planname: "3 months" },
    { id: 14.00, planname: "6 months" },
    { id: 24.00, planname: "12 months" },
  ];
  const cartid = sessionStorage.getItem("cartId");
  const fetchCartIndex = async () => {
    const cartid = sessionStorage.getItem("cartId");
    try {
      if (cartid) {
        const response = await fetch(
          `https://vanityapi.adef.tech/Cart/Index?project_id=${Project_Id}&cart_id=${cartid}`
        );
        const data = await response.json();
        if (data.length > 0 && data !== "Cart is empty") {
          setCartData(data);
          setProductid(data[0].product_id);
          setCartCount(data.length);
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchCartAmount = async () => {
    try {
      if (cartid) {
        const response = await fetch(
          `https://vanityapi.adef.tech/Cart/Cartamount?project_id=${Project_Id}&cart_id=${cartid}`
        );
        const data = await response.json();
        if (data) {
          debugger;
          const amount = parseFloat(data.price).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          setCartAmount(amount);
          setPaypalAmount(data.price);
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  fetchCartIndex();
  fetchCartAmount();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cartIndexResponse, cartAmountResponse] = await Promise.all([
          fetch(
            `https://vanityapi.adef.tech/Cart/Index?project_id=${Project_Id}&cart_id=${cartid}`
          ),
          fetch(
            `https://vanityapi.adef.tech/Cart/Cartamount?project_id=${Project_Id}&cart_id=${cartid}`
          ),
        ]);

        const cartIndexData = await cartIndexResponse.json();
        const cartAmountData = await cartAmountResponse.json();

        if (cartIndexData.length > 0 && cartIndexData !== "Cart is empty") {
          setCartData(cartIndexData);
          setProductid(cartIndexData[0].product_id);
          setCartCount(cartIndexData.length);
        }

        if (cartAmountData) {
          
          const amount = parseFloat(cartAmountData.price).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
          setCartAmount(amount);
        }

        setLoading(false);
      } catch (error) {
        console.error("API Error:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [Project_Id, cartid]);

  useEffect(() => {
    const fetchData = async () => {
      const cartid = sessionStorage.getItem("cartId");

      try {
        if (cartid) {
          const response = await fetch(
            `https://vanityapi.adef.tech/Cart/Index?project_id=${Project_Id}&cart_id=${cartid}`
          );
          const data = await response.json();
          if (data.length > 0 && data !== "Cart is empty") {
            setCartData(data);
            debugger
            setProductid(data[0].product_id);
            setCartCount(data.length);
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("API Error:", error);
        setLoading(false);
      }

      try {
        if (cartid) {
          const response = await fetch(
            `https://vanityapi.adef.tech/Cart/Cartamount?project_id=${Project_Id}&cart_id=${cartid}`
          );
          const data = await response.json();
          if (data) {
            const amount = parseFloat(data.price).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            setCartAmount(amount);
          }
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    fetchData();
  }, [Project_Id, cartid]);

  const handlePlanSelect = async (selectedPlanId, index) => {
    try {
      const selectedParkingPlan = parkingPlans.find(
        (plan) => plan.id === parseInt(selectedPlanId)
      );
      setSelectedPlans((prevSelectedPlans) => ({
        ...prevSelectedPlans,
        [index]: selectedParkingPlan.id,

      }));
      return selectedParkingPlan.id;
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const handleProceed = async () => {
    try {
      const selectedPlanId = cartData.map(
        (item, index) => selectedPlans[index]
      );

      const selectedParkingPlan = parkingPlans.find(
        (plan) => plan.id === parseInt(selectedPlanId)
      );

      const cartId = sessionStorage.getItem("cartId");
      var parkingAmount = selectedParkingPlan.id;
      
      var parkingname = selectedParkingPlan.planname;
      const formData = {
        cartId,
        parkingAmount,
        parkingname,
        authorid
      };
      const data = new URLSearchParams(formData);

      const response = await fetch(
        "https://vanityapi.adef.tech/Cart/ParkingAmount",
        {
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      
      const responseData = await response.json();
      sessionStorage.setItem("parkingplan", responseData.parkingplanname);
      if (responseData.responseCode === 0) {
        const showSuccessToast = () => {
          toast.success(responseData.responsemessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        setIsSuccess(true);
        // Call the cart amount API after parking plan success
        await fetchCartAmount();
        // Call the cart index API after parking plan success
        await fetchCartIndex();
        setParkingModalOpen(true);

        showSuccessToast();
        const cartid = sessionStorage.getItem("cartId");

        setAppliedPlans((prevAppliedPlans) => ({
          ...prevAppliedPlans,
          [cartid]: {
            planId: selectedParkingPlan.id,
            planName: selectedParkingPlan.planname,
          },
        }));
      }
      // ... (your API call logic)
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  useEffect(() => {
    if (timerComplete) {
      // Execute your function when the timer completes
      console.log("Timer complete useEffect triggered");
      handleTimerCompletion();
    }
  }, [timerComplete]);

  useEffect(() => {
    if (timerComplete) {
      // Execute your function when the timer completes
      console.log("Timer complete useEffect #2 triggered");
      const cartid = sessionStorage.getItem("cartId");
      cartDelete(cartid);
    }
  }, [timerComplete]);
  const parkingplan = sessionStorage.getItem("parkingplan");
  const handleTimerCompletion = () => {
    // Add your logic here to execute when the timer completes
    console.log("Timer completed! Add your logic here.");
    const cartid = sessionStorage.getItem("cartId");
    

    cartDelete(cartid);
  };
  if (loading) {
    return <p>Loading...</p>;
  }

  if (cartData.length === 0) {
    
    return (
      <Container>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
        >
          <path
            d="M10.75 21.5C10.75 21.8214 10.6547 22.1356 10.4761 22.4028C10.2976 22.67 10.0438 22.8783 9.74686 23.0013C9.44993 23.1243 9.1232 23.1565 8.80798 23.0938C8.49276 23.0311 8.20321 22.8763 7.97595 22.649C7.74869 22.4218 7.59392 22.1322 7.53122 21.817C7.46852 21.5018 7.5007 21.1751 7.6237 20.8781C7.74669 20.5812 7.95497 20.3274 8.2222 20.1489C8.48943 19.9703 8.80361 19.875 9.125 19.875C9.55598 19.875 9.9693 20.0462 10.274 20.351C10.5788 20.6557 10.75 21.069 10.75 21.5ZM19.6875 19.875C19.3661 19.875 19.0519 19.9703 18.7847 20.1489C18.5175 20.3274 18.3092 20.5812 18.1862 20.8781C18.0632 21.1751 18.031 21.5018 18.0937 21.817C18.1564 22.1322 18.3112 22.4218 18.5385 22.649C18.7657 22.8763 19.0553 23.0311 19.3705 23.0938C19.6857 23.1565 20.0124 23.1243 20.3094 23.0013C20.6063 22.8783 20.8601 22.67 21.0386 22.4028C21.2172 22.1356 21.3125 21.8214 21.3125 21.5C21.3125 21.069 21.1413 20.6557 20.8365 20.351C20.5318 20.0462 20.1185 19.875 19.6875 19.875ZM24.527 7.11367L21.6294 16.5295C21.4776 17.0287 21.169 17.4656 20.7493 17.7755C20.3296 18.0854 19.8212 18.2518 19.2995 18.25H9.53836C9.00919 18.248 8.49491 18.0747 8.07252 17.7559C7.65013 17.4371 7.34236 16.9901 7.19531 16.4818L3.52078 3.625H1.8125C1.59701 3.625 1.39035 3.5394 1.23798 3.38702C1.0856 3.23465 1 3.02799 1 2.8125C1 2.59701 1.0856 2.39035 1.23798 2.23798C1.39035 2.0856 1.59701 2 1.8125 2H3.52078C3.87362 2.00117 4.21659 2.11658 4.49835 2.32896C4.78012 2.54134 4.98553 2.83926 5.08383 3.17812L5.9075 6.0625H23.75C23.8771 6.06245 24.0025 6.09225 24.1161 6.14949C24.2296 6.20672 24.3281 6.28981 24.4037 6.39206C24.4793 6.49431 24.5298 6.61286 24.5512 6.7382C24.5726 6.86353 24.5643 6.99213 24.527 7.11367ZM22.6501 7.6875H6.37164L8.75734 16.0359C8.80588 16.2056 8.90835 16.3549 9.04926 16.4612C9.19018 16.5675 9.36186 16.625 9.53836 16.625H19.2995C19.4734 16.6251 19.6427 16.5694 19.7825 16.4661C19.9224 16.3628 20.0254 16.2174 20.0765 16.0512L22.6501 7.6875Z"
            fill="black"
          />
        </svg>
        <p>Your Shopping cart is currently empty.</p>
      </Container>
    );
  }

  const cartDelete = (id) => {
    if (id) {
      const formData = { id: id };
      const data = new URLSearchParams(formData);

      fetch("https://vanityapi.adef.tech/Cart/Delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.responseCode === 1) {
            const showSuccessToast = () => {

              toast.success("Cart item deleted.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            };
            showSuccessToast();
            sessionStorage.removeItem("cartId");
            sessionStorage.removeItem("parkingplan");
            window.location = "/";
          } else {
            const showErrorToast = () => {
              toast.error(data.responsemessage, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            };
            showErrorToast();
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    }
  };

  var closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Container className="pt-4">
        <div>
          {showTimer && (
            <p>
              Remaining time: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}{" "}
              minutes
            </p>
          )}
        </div>

        <Table>
          <tbody>
            {cartData.map((item, index) => (
              <React.Fragment key={index}>
                <tr key={item.product_id} className="cartTableRow">
                  <td>
                    <div className="phoneicon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                      >
                        <path
                          d="M29.4284 3.27612L31.1994 3.75462C33.3652 4.3407 35.3395 5.48437 36.9254 7.07156C38.5113 8.65875 39.6534 10.634 40.2377 12.8003L40.7144 14.5695L37.176 15.5246L36.6975 13.7555C36.28 12.2081 35.4641 10.7972 34.3311 9.66359C33.1981 8.52997 31.7877 7.71321 30.2406 7.29479L28.4714 6.81446L29.4284 3.27612ZM1.83305 3.66662H17.5631L20.2892 15.9316L16.8792 19.3416C18.8833 22.4627 21.5365 25.1153 24.6581 27.1186L28.0681 23.7105L40.333 26.4366V42.1666H38.4997C31.4421 42.1776 24.5327 40.1424 18.608 36.3073C14.2397 33.4801 10.5195 29.76 7.69238 25.3916C3.85742 19.4669 1.82217 12.5575 1.83305 5.49996V3.66662ZM5.54922 7.33329C5.85764 13.0532 7.65842 18.5927 10.7724 23.4006C13.3177 27.333 16.6667 30.6819 20.5991 33.2273C25.4069 36.3414 30.9464 38.1422 36.6664 38.4505V29.3773L29.2359 27.7273L25.2502 31.7148L24.0347 31.0236C19.4197 28.4008 15.5989 24.58 12.9761 19.965L12.2849 18.7495L16.2724 14.7638L14.6224 7.33329H5.54922ZM27.7527 9.46912L29.5237 9.94762C30.6066 10.2407 31.5938 10.8125 32.3867 11.6061C33.1797 12.3997 33.7507 13.3873 34.0429 14.4705L34.5196 16.2396L30.9812 17.1948L30.5027 15.4256C30.3773 14.9614 30.1324 14.5381 29.7924 14.1981C29.4524 13.8581 29.0291 13.6132 28.5649 13.4878L26.7957 13.0093L27.7527 9.46912Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </td>
                  <td>
                    <p className="numbername">{item.name}</p>
                    <p className="location">{item.state}</p>
                  </td>
                  <td>
                    {parkingplan ? (
                      <p>Parking Plan: {parkingplan}</p>
                    ) : (
                      <select
                        value={selectedPlans[index] || ""}
                        onChange={(e) => handlePlanSelect(e.target.value, index)}
                      >
                        {/* <option value="" >
                          No Parking plan
                        </option> */}
                        {parkingPlans.map((plan) => (
                          <option key={plan.id} value={plan.id}>
                            {plan.planname}
                          </option>
                        ))}
                      </select>
                    )}
                  </td>
                  <td className="text-center">{item.dimension}</td>

                  <td className="text-center price">
                    $
                    {parseFloat(item.price).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    {selectedPlanDetails && selectedPlanDetails.amount !== 0 && (
                      <span>
                        + ${parseFloat(selectedPlanDetails.amount).toFixed(2)}
                      </span>
                    )}
                    {isSuccess ? null : (
                      <>
                        {selectedPlans[index] && (
                          <span>+ ${selectedPlans[index]} </span>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    <div
                      className="closeicon"
                      onClick={() => cartDelete(cartid)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 12L7 7M12 12L17 17M12 12L17 7M12 12L7 17"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </td>
                  {/* Add more table cells as needed */}
                </tr>
                <tr>
                  {item.rate > 5000 && (
                    <MakeAnOfferButton
                      productId={item.name}
                      show={isOpen}
                      onHide={onRequestClose}
                      isOpen={closeModal}
                    />
                  )}
                </tr>
                <tr>
                  {appliedPlan[item.cartid] && (
                    <p className="applied-plan">
                      Applied Plan: {appliedPlan[item.cartid].planName}
                    </p>
                  )}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col md={4}></Col>
          <Col md={4}></Col>
          <Col md={4}></Col>
        </Row>

        <Row>
          <Col md={7}>
            {authorid ? (
              isSuccess ? null : (
                <>
                  {Object.keys(selectedPlans).length > 0 && (
                    <>
                      <p>Do you want to proceed with Parking plan:</p>
                      <Button className="btn" onClick={handleProceed}>
                        Proceed
                      </Button>
                    </>
                  )}
                </>
              )
            ) : (
              <>
                <p>You need to login to Proceed</p>
                {/* <Button className="btn mb-5" onClick={handleShowLoginModal}>
                Login
              </Button>
              <Login show={showLoginModal} handleClose={handleCloseLoginModal} openSignUpModal={handleShowSignUpModal} /> */}
                <Sign show={showSignUpModal} handleClose={handleCloseSignUpModal} openLoginModal={handleShowLoginModal} />
              </>
            )}
          </Col>

          <Col md={5}>
            <p className="m-auto cartamount d-flex justify-content-center">
              {" "}
              <span className="fw-bold">Total: ${cartAmount}</span>
            </p>
            {authorid ? (
              <Link to="/paypal">
                <PayPalComponent amount={paypalAmount} productid={productid} />
                {/* <Button className="btn" amount={cartAmount} productid={productid} onClick={() => closeModal()}>Checkout</Button> */}
              </Link>
            ) : (
              <></>
            )}
            {isModalOpen && (
              <ParkingPlanModal
                isOpen={isParkingModalOpen}
                onClose={closeParkingModal}
              />
            )}
          </Col>
        </Row>
      </Container>

      <ToastContainer />
    </>
  );
}

export default Cart;
